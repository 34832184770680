<template lang="pug">
div()
  v-list(subheader two-line)
    div(
      v-for='(item, i) in itemsSorted'
      :key='item._id'
    )
      v-list-tile(
        avatar
      ).list-item
        v-list-tile-avatar(v-if="item.pi.dashboard.image.url" tile @click="select(item)")
          img(:src="item.pi.dashboard.image.url")
        v-list-tile-content(@click="select(item)")
          v-list-tile-title
            slot(name="line1" :item="item")
          v-list-tile-sub-title
            slot(name="line2" :item="item")
        v-list-tile-action
          PriorityPicker(
            :_idProductionInstance="item.pi._id"
            :priority="item.pi.dashboard.priority.art"
          )

</template>
<script>
import moment from 'moment'
import PriorityPicker from './priority-picker'

export default {
  components: { PriorityPicker },
  props: {
    items: { type: Array }
  },
  computed: {
    currentUser () { return this.$store.state.users.item },
    itemsSorted () {
      let itemsSorted = this.items.sort((a, b) => {
        const C = a.pi.dashboard.priority.art
        const D = b.pi.dashboard.priority.art
        if (C > D) return 1
        if (C < D) return -1
        const A = a.pi.dateProduction
        const B = b.pi.dateProduction
        if (A > B) return 1
        if (A < B) return -1

        return 0
      })

      if (this.currentUser.role === 'Admin') {
        return itemsSorted
      } else {
        return itemsSorted.slice(0, 10)
      }
    }
  },
  methods: {
    select (item) { this.$emit('select', item) }
  }
}
</script>
