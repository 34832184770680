<template lang="pug">
v-select.pl-3(
  label="priority"
  v-model.number="priorityInput"
  :items="items"
  @change="changePriority"
  dense
  width="25%"
)
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['_idProductionInstance', 'priority'],
  data: () => ({
    items: [1, 2, 3, 4, 5],
    priorityInput: null
  }),
  methods: {
    changePriority () {
      const items = this.$store.getters['productionInstances/items']
      const pi = items.find(x => x._id === this._idProductionInstance)
      const results =  []
      items.forEach(item => {
        if (item.job === pi.job) {
          item.dashboard.priority.art = this.priorityInput
          this.$store.commit('productionInstances/item', item)
          this.$store.dispatch('productionInstances/_UPDATE')
        }
        results.push(item)
      })
      this.$store.commit('productionInstances/items', results)
    }
  },
  watch: {
    priority: {
      handler () { this.priorityInput = this.priority },
      immediate: true
    }
  },
  created () {  }
}
</script>
