<template lang="pug">
div(v-if="loaded")
  ListSchedule(
    :items="items"
    @select="select"
  )
    template(slot="line1" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs2) {{ item.type }}
        v-flex(xs10) {{item.pi.dashboard.designName}}
          span(v-if="item.pi.dashboard.jobSubtitle")  - {{item.pi.dashboard.jobSubtitle}}
          span(v-if="item.type === 'rip'").font-weight-bold  - {{item.pi.location.template.name}}
    template(slot="line2" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs2)
        v-flex(xs10) order: {{item.pi.dashboard.orderDisplayName}} / job#: {{item.pi.dashboard.jobNumber}}
</template>

<script>
import ListSchedule from './list'

export default {
  components: { ListSchedule },
  computed: {
    debug () {
      return this.$store.getters['productionInstances/items'].map(x => x.dashboard)
    },
    items () {
      const pis = this.$store.getters['productionInstances/items']
      let rips = []
      let mocks = []

      // process PI's to add to respective list
      pis.forEach(pi => {
        if (pi.dashboard.jobProgress.isMockApproved) {
          rips.push(pi)
        }
        if (!pi.dashboard.jobProgress.isMockSent) {
          mocks.push(pi)
        }
      })

      console.log("mocks", mocks)
      // reduce mockup list to unique designs
      // sort first (so that revisions are processed first)
      mocks.sort((a, b) => {
        const A = a.dashboard.isNeedsRevision
        const B = b.dashboard.isNeedsRevision
        if (A === true) return 1
        if (B === true) return -1
        return 0
      })
      // now find unique
      // const seen = []
      // mocks.forEach(item => {
      //   let found = seen.find(el => item.job === el.job)
      //   if (found === undefined) { seen.push(item) }
      // })
      // mocks = seen

      // add all items to one list with labels
      const results = []
      mocks.forEach(pi => {
        if (results.find(a => a.pi.design === pi.design)) { return }
        const type = pi.dashboard.isNeedsRevision ? 'mockup revision' : 'mockup'
        const item = { type, pi }
        results.push(item)
      })
      rips.forEach(pi => {
        // if(results.find(a => a.pi.location._id === pi.location._id)) { return }
        const type = 'rip'
        const item = { type, pi }
        results.push(item)
      })

      return results
    },
    loaded () { return this.$store.state.productionInstances.loaded }
  },
  methods: {
    select (item) {
      if (item.type === 'rip') {
        // bring up a PI detail
        this.$router.push({ path: '/dashboards/art/' + item.pi._id })
      } else if (item.type === 'mockup' || item.type === 'mockup revision') {
        this.$router.push({ path: '/jobs/' + item.pi.job })
      }
    },
    formatDate (date) {
      if (!date) { return '' }
      return moment(date).format('L')
    }

  },
  async created () {
    await this.$store.dispatch('dashboards/_LOAD_ART')
  }
}
</script>
